import WebApps from '../../assets/scripts/master';
import BuildDropdown from './dropdown';
import React from 'react';

const BuildSearchBar = (props) => {
  return (
    <React.Fragment>
      <div className='container'>
        <input
          onChange={props.settings.function}
          className='form-control'
          style={{ width: window.innerWidth > 1500 ? 1000 : 525 }}
          type='text'
          id='search-bar'
        />
      </div>
      {
        // Only display the searchbar if the user is signed in
        WebApps.Mappings.isset(props.settings.filters, 'enabled', false) ===
        true ? (
          // Add a dropdown list of filters to search by if requested
          <BuildDropdown options={props.settings.options} />
        ) : null
      }
    </React.Fragment>
  );
};

export default BuildSearchBar;
