import BuildPortletTitle from '../spa-master/components/content';
import React, { Component } from 'react';
import WebApps from '../spa-master/assets/scripts/master';
import NavBar from '../spa-master/components/archive/navbar';

import $ from 'jquery';

import {
  getUsersByGroup,
  getAllUserPresence,
} from '../spa-master/assets/scripts/microsoft/graph-aad';

const { isset } = WebApps.Mappings;

class UserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      provider: props.provider,
      contacts: [],
      contactPresence: {},
    };
  }

  // Trigger presence refresh function to run every 30 seconds
  startContactPresenceRefresh() {
    // Schedule 30 second intervals
    setInterval(() => this.refreshUserPresence(), 30000);
  }

  // Prevent memory leak by ending all scheduled tasks
  componentWillUnmount() {
    clearInterval();
  }

  // Rebuild the contacts array and include the updated presence for each contact
  refreshUserPresence = () => {
    let contacts = WebApps.Mappings.isset(this.state, 'contacts', []);

    // Pull all of the user ids into a single array
    let userIds = WebApps.Arrays.makeSingleDem(contacts, 'id');

    if (userIds.length > 0) {
      getAllUserPresence(this.state.provider, this.handleGraphCallback, {
        ids: userIds,
      });
    }
  };

  handleGraphCallback = (data, endpoint, callbackArgs = null) => {
    // We want the singular identifier for this endpoint
    let splitEndpoint = endpoint
      .replace('https://graph.microsoft.com/v1.0/', '')
      .split('/');

    switch (splitEndpoint[splitEndpoint.length - 1].split('?')[0]) {
      case 'users':
      case 'members':
        // Filtering 'users' without a job title
        let contacts = data.value.filter(
          (contact) => contact.jobTitle !== null
        );
        this.setState({
          contacts: WebApps.Arrays.sortByKey(this.state.contacts.concat(contacts), 'displayName'),
        });
        this.refreshUserPresence();
        break;
      case 'getPresencesByUserId':
        let currentPresenceMap = this.state.contactPresence;
        data.value.map((contact) => {
          switch (contact.availability) {
            case 'Offline':
              contact.presenceStyle = 'black';
              break;
            case 'Available':
              contact.presenceStyle = '#58b54a';
              break;
            case 'Busy':
              contact.presenceStyle = '#ed214d';
              break;
            case 'Away':
              contact.presenceStyle = 'orange';
              break;
            default:
              contact.presenceStyle = 'black';
          }
          currentPresenceMap[contact.id] = contact;
          return true;
        });

        // Perhaps it makes sense to update a separate state object and then join
        this.setState({
          contactPresence: currentPresenceMap,
        });

        $('#users-table').DataTable().ajax.reload();
        break;

      default:
    }
  };

  // We are going to filter through every field that is returned from the Graph api assuming we will do pre filtering at the query level
  getUserData = (callback) => {
    let usersToDisplay = WebApps.Mappings.isset(this.state, 'contacts', [])
      // Map the contacts to their presence before filtering to allow for searching by presence
      .map((contact) => {
        const contactPresenceInfo = WebApps.Mappings.isset(
          this.state.contactPresence,
          contact.id,
          {
            presenceStyle: 'black',
            activity: 'Unknown',
            outOfOfficeSettings: {
              message: '',
              isOutOfOffice: false,
            },
          }
        );

        // Add presence information to contacts for filterability
        contact.presenceStyle = contactPresenceInfo.presenceStyle;
        contact.availability = contactPresenceInfo.availability;
        contact.activity = contactPresenceInfo.activity;

        const contactOutOfOfficeSettings = WebApps.Mappings.isset(
          contactPresenceInfo,
          'outOfOfficeSettings',
          {
            message: '',
            isOutOfOffice: false,
          }
        );
        // Searching through ooo settings requires we format the results first
        contact.message =
          contactOutOfOfficeSettings.message === null
            ? ''
            : contactOutOfOfficeSettings.message;
        contact.isOutOfOffice = contactOutOfOfficeSettings.isOutOfOffice
          ? 'true'
          : 'false';
          
        const contactStatus = isset(
          contactPresenceInfo,
          'statusMessage',
          {
            message: ''
          }
        );

        // Searching through ooo settings requires we format the results first
        contact.statusMessage = contactStatus.message.content === null || contactStatus.message.content === '' ? '' : isset(contactStatus.message, 'content', '');

        return contact;
      })
      .map((contact) => {
        contact.rate = isset(
          contact,
          'extension_b2a84385320b4220a86caa54eb680c34_wruRate'
        );
        contact.schedule = isset(
          contact,
          'extension_b2a84385320b4220a86caa54eb680c34_wruSchedule'
        );
        contact.pronouns = isset(
          contact,
          'extension_b2a84385320b4220a86caa54eb680c34_wruPronouns'
        );
        contact.hireDate = isset(
          contact,
          'extension_b2a84385320b4220a86caa54eb680c34_wruHireDate'
        );

        return contact;
      });

    callback(usersToDisplay);
  };

  componentDidMount() {
    getUsersByGroup(this.state.provider, 'staff', this.handleGraphCallback)
    this.startContactPresenceRefresh();

    this.buildUserTable();
  }

  buildUserTable = () => {
    WebApps.Datatables.buildTable(
      'users-table',
      {
        columns: [
          {
            source: 'availability',
            title: 'Presence',
            align: 'left',
            render: function (data, type, row) {
              return data ? data.replace('Presence', '') : '-';
            },
          },
          {
            source: 'displayName',
            title: 'Name',
            align: 'left',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          {
            source: 'givenName',
            title: 'Given Name',
            align: 'left',
            visible: false,
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          {
            source: 'jobTitle',
            title: 'Job Title',
            align: 'left',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          {
            source: 'officeLocation',
            title: 'Office',
            align: 'left',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          {
            source: 'department',
            title: 'Department',
            align: 'left',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          {
            source: 'schedule',
            title: 'Schedule',
            align: 'left',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          {
            source: 'rate',
            title: 'Rate',
            align: 'left',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          {
            source: 'pronouns',
            title: 'Pronouns',
            align: 'left',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          {
            source: 'hireDate',
            title: 'Hire Date',
            align: 'left',
            render: function (data, type, row) {
              return data ? data : '-';
            },
          },
          {
            source: 'message',
            title: 'OOO',
            align: 'center',
            render: function (data, type, row) {
              return data !== '' || row[row.length - 1] !== ''
                ? '<span title="' +
                    (data ? ('Out of Office Message: ' + data) : '') + '&#10&#10' + (row[row.length - 1] ? ('Teams Status: ' + row[row.length - 1]) : '') +
                    '">' +
                    WebApps.Content.getIcon('open-envelope', 18) +
                    '</span>'
                : '-';
            },
          },
          {
            source: 'statusMessage',
            title: 'OOO',
            align: 'center',
            visible: false,
            render: function (data, type, row) {
              return data ? data : '';
            },
          },
        ],
        recordType: 'Users',
      },
      this.getUserData
    );
  };
  render() {
    return (
      <React.Fragment>
        <NavBar
          brand='Teams WhereRU'
          links={[
            {
              label: 'Tile View',
              visible: true,
              path: '/tile',
            },
            {
              label: 'Photo View',
              visible: true,
              path: '/photos',
            },
          ]}
        />
        <div className='row mr-1'>
          <div className='col-md-12 ml-2'>
            <div className='card'>
              <center>
                <BuildPortletTitle
                  icon='user-group-man-woman'
                  title='Users'
                  subTitle='Table will refresh every 30 seconds'
                />
              </center>
              <div className='card-body'>
                <table id='users-table'></table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default UserTable;
