import WebApps from '../../assets/scripts/master';
import { Link } from 'react-router-dom';
import BuildDropdown from './dropdown';
import BuildSearchBar from './searchbar';

const NavBar = (props) => {
  // Set defaults if the property was not set
  const links = WebApps.Mappings.isset(props, 'links', []);
  const search = WebApps.Mappings.isset(props, 'search', { enabled: false });
  const userDropdown = WebApps.Mappings.isset(props, 'userDropdown', {
    enabled: false,
  });

  return (
    <nav className='navbar navbar-expand-lg bd-navbar mb-2'>
      <span className='navbar-brand'>{props.brand}</span>
      {links.length === 0 ? null : BuildNavBarLinks(links)}
      <div className={window.innerWidth > 1500 ? 'container ml-5' : ''}>
        {
          // Only display the searchbar by request
          WebApps.Mappings.isset(search, 'enabled', true) === true ? (
            <BuildSearchBar settings={search} />
          ) : null
        }
      </div>
      {
        // Only display the filter dropdown if requested for this application
        WebApps.Mappings.isset(userDropdown, 'enabled', false) === true ? (
          <BuildDropdown
            options={userDropdown.options}
            user={userDropdown.user}
          />
        ) : null
      }
    </nav>
  );
};

const BuildNavBarLinks = (links) => {
  return (
    <ul className='navbar-nav'>
      {links
        .filter((link) => link.visible === true)
        .map((link) => {
          if (WebApps.Mappings.isset(link, 'action', false) === false) {
            return (
              <li key={link.path} className='nav-item m-2'>
                <Link to={link.path} className='nav-link'>
                  {link.label}
                </Link>
              </li>
            );
          } else {
            return (
              <a
                key={link.label}
                onClick={link.action()}
                className='nav-link m-2'
              >
                {link.label}
              </a>
            );
          }
        })}
    </ul>
  );
};

export default NavBar;
