import { MsalAuthProvider, LoginType } from 'react-aad-msal';
// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority:
        'https://login.microsoftonline.com/dd768905-3acf-4499-b609-7076a5edaefd',
      clientId: process.env.REACT_APP_AZ_APP_ID,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
  },
  {
    scopes: [
      'openid',
      'profile',
      'User.Read',
      'User.ReadBasic.All',
      'Directory.Read.All',
      'Presence.Read.All',
    ],
  },
  {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin,
  }
);
