import React from 'react';
import WebApps from '../../spa-master/assets/scripts/master';

export default function BuildCardHeader(props) {
  return (
    <React.Fragment>
      <h5 className='card-header' style={{ height: 47 }}>
        {props.icon ? (
          <img
            alt='Card Icon'
            className='mb-1 mr-1'
            src={WebApps.Content.getIcon(props.icon, 15, true)}
          />
        ) : null}
        {props.title}
        <span
          className='card-subtitle ml-1 text-muted'
          style={{ fontSize: 15 }}
        >
          {props.subTitle}
        </span>
      </h5>
    </React.Fragment>
  );
}
