import React from 'react';

const BuildCard = (options) => {
  return (
    <React.Fragment>
      <div className='col-large-12 m-2'>
        <div className='card text-center'>
          <div className='card-body'>
            <h3>{options.title}</h3>
            <h5>{options.subtitle}</h5>
            <br />
            <div
              class='spinner-border'
              role='status'
              style={{ color: '#563d7c' }}
            >
              <span class='sr-only'>Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BuildCard;
