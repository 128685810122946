// Add here the endpoints for MS Graph API services you would like to use.
const baseUrl = 'https://graph.microsoft.com/v1.0/';

const graphConfig = {
  presenceBatchEndpoint: baseUrl + 'communications/getPresencesByUserId',
  usersEndpoint: baseUrl + 'users',
  meEndpoint: baseUrl + 'me',
  graphGroupsEndpoint: baseUrl + 'groups',
  graphUsersEndpoint: baseUrl + 'users',
  graphMeEndpoint: baseUrl + 'me',
  graphOrganizationEndpoint: baseUrl + 'organization',
};

export default graphConfig;
