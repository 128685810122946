import React, { Component } from 'react';
import BuildCard from '../spa-master/components/archive/card';
import UserCard from '../components/usercard';
import WebApps from '../spa-master/assets/scripts/master';
import NavBar from '../spa-master/components/archive/navbar';
import BuildButton from '../spa-master/components/archive/button';
import BuildDropdown from '../spa-master/components/archive/dropdown';

import {
  getUserPhoto,
  getTenantName,
  getUsersByGroup,
  getAllUserPresence,
} from '../spa-master/assets/scripts/microsoft/graph-aad';

const { isset } = WebApps.Mappings;

class UserTiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      provider: props.provider,
      contacts: [],
      contactPhotos: {},
      contactPresence: {},
      searchValue: '',
      selectedFilters: {},
      tenantName: null,
      windowWidth: window.innerWidth,
      filters: {
        availability: ['presenceUnknown'],
        department: [],
        officeLocation: [],
      },
    };
  }

  // Trigger presence refresh function to run every 10 seconds
  startContactPresenceRefresh() {
    setInterval(() => this.refreshUserPresence(), 10000);
  }

  // Prevent memory leak by ending all scheduled tasks
  componentWillUnmount() {
    clearInterval();
  }

  getSavedFilters() {
    var selectedFilters = localStorage.getItem('selectedFilters');

    if (selectedFilters === null) {
      localStorage.setItem('selectedFilters', JSON.stringify({}));
    } else {
      //The state of selectedFilters needs to be set before calling 'handleFilterChange' since it will use values from the state variable.
      this.setState({
        selectedFilters: JSON.parse(selectedFilters),
      });

      Object.keys((selectedFilters = JSON.parse(selectedFilters))).forEach(
        (filter) => {
          this.handleFilterChange(null, filter);
        }
      );
    }
  }

  componentDidMount() {
    getTenantName(this.state.provider, this.handleGraphCallback);

    // Wait 2 seconds before attempting to get users
    setTimeout(
      () => {
        getUsersByGroup(this.state.provider, 'staff', this.handleGraphCallback)
      },
      2000
    );
    this.startContactPresenceRefresh();
  }

  // Rebuild the contacts array and include the updated presence for each contact
  refreshUserPresence = () => {
    let contacts = isset(this.state, 'contacts', []);

    // Pull all of the user ids into a single array
    let userIds = WebApps.Arrays.makeSingleDem(contacts, 'id');

    if (userIds.length > 0) {
      getAllUserPresence(this.state.provider, this.handleGraphCallback, {
        ids: userIds,
      });
    }

    // Everytime that the presence refreshes, we will throw in a window size check
    this.setState({ windowWidth: window.innerWidth });
  };

  // Function passed through to the searchbar component in the navbar
  handleSearchChange = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  handleGraphCallback = (data, endpoint, callbackArgs = null) => {
    if (isset(data, 'error')) {
      console.log('GRAPH API ERROR: ', data['error']);
    } else {
      // We want the singular identifier for this endpoint
      let splitEndpoint = endpoint
        .replace('https://graph.microsoft.com/v1.0/', '')
        .split('/');

      switch (splitEndpoint[splitEndpoint.length - 1].split('?')[0]) {
        case 'users':
        case 'members':
          // Filtering 'users' without a job title
          let contacts = data.value
            .filter(
              (contact) =>
                contact.jobTitle !== null && contact.accountEnabled !== null
            )
            .map((contact) => {
              contact.rate = isset(
                contact,
                'extension_b2a84385320b4220a86caa54eb680c34_wruRate'
              );
              contact.schedule = isset(
                contact,
                'extension_b2a84385320b4220a86caa54eb680c34_wruSchedule'
              );
              contact.pronouns = isset(
                contact,
                'extension_b2a84385320b4220a86caa54eb680c34_wruPronouns'
              );
              contact.hireDate = isset(
                contact,
                'extension_b2a84385320b4220a86caa54eb680c34_wruHireDate'
              );

              return contact;
            });
          
          this.setState({
            contacts: WebApps.Arrays.sortByKey(this.state.contacts.concat(contacts), 'displayName'),
          });

          // These must wait until we have users
          this.getAllUserPhotos();
          this.getQuickFilterOptions();
          this.getSavedFilters();
          break;

        case '$value':
          // If a user does not have the requested photo size avaible,
          // we are unable to display and will need to make another call
          if (data.type !== 'image/jpeg') {
            break;
          }
          let urlCreator = window.URL || window.webkitURL;
          let imageUrl = urlCreator.createObjectURL(data);

          // Store copy of current state
          let contactPhotoMap = this.state.contactPhotos;

          // Add  user photos using the id as the key
          contactPhotoMap[callbackArgs.userId] = imageUrl;

          // Update the state
          this.setState({
            contactPhotos: contactPhotoMap,
          });

          break;
        case 'getPresencesByUserId':
          console.log(data)
          let currentPresenceMap = this.state.contactPresence;
          data.value.map((contact) => {
            switch (
              contact.availability //https://docs.microsoft.com/en-us/graph/api/resources/presence?view=graph-rest-1.0
            ) {
              case 'Offline':
                contact.presenceStyle = '#838fa3';
                break;
              case 'Available':
                contact.presenceStyle = '#58b54a';
                break;
              case 'Busy':
                contact.presenceStyle = '#ed214d';
                break;
              case 'Away':
                contact.presenceStyle = 'orange';
                break;
              default:
                contact.presenceStyle = 'black';
            }
            currentPresenceMap[contact.id] = contact;
            return true;
          });

          this.resetPresenceFilter();
          this.setState({
            contactPresence: currentPresenceMap,
          });
          break;
        case 'organization':
          if (isset(data.value[0], 'verifiedDomains')) {
            var tenantName = data.value[0].verifiedDomains.filter(
              (domain) => domain.isInitial === true
            );

            this.setState({
              tenantName: tenantName[0].name.replace('.onmicrosoft.com', ''),
            });
          }
        default:
      }
    }
  };

  getAllUserPhotos = () => {
    this.state.contacts.map((contact) =>
      getUserPhoto(this.state.provider, this.handleGraphCallback, contact.id)
    );
  };

  // We are going to filter through every field that is returned from the Graph api assuming we will do pre filtering at the query level
  generateUserCards() {
    let usersToDisplay = isset(this.state, 'contacts', [])
      // Map the contacts to their presence before filtering to allow for searching by presence
      .map((contact) => {
        const contactPresenceInfo = isset(
          this.state.contactPresence,
          contact.id,
          {
            presenceStyle: 'black',
            availability: 'PresenceUnknown',
            activity: 'PresenceUnknown',
            outOfOfficeSettings: {
              message: '',
              isOutOfOffice: false,
            },
          }
        );

        // Add presence information to contacts for filterability
        contact.presenceStyle = contactPresenceInfo.presenceStyle;
        contact.availability = contactPresenceInfo.availability;
        contact.activity = contactPresenceInfo.activity;

        const contactOutOfOfficeSettings = isset(
          contactPresenceInfo,
          'outOfOfficeSettings',
          {
            message: '',
            isOutOfOffice: false,
          }
        );
        // Searching through ooo settings requires we format the results first
        contact.message =
          contactOutOfOfficeSettings.message === null ||
          contactOutOfOfficeSettings.message === ''
            ? ''
            : contactOutOfOfficeSettings.message;
        contact.isOutOfOffice = contactOutOfOfficeSettings.isOutOfOffice
          ? 'true'
          : 'false';
          
        const contactStatus = isset(
          contactPresenceInfo,
          'statusMessage',
          {
            message: ''
          }
        );

        // Searching through ooo settings requires we format the results first
        contact.statusMessage = contactStatus.message.content === null || contactStatus.message.content === '' ? '' : isset(contactStatus.message, 'content', '');

        return contact;
      })
      // Remove contacts that do not have a field value that matches
      .filter((contact) => {
        // We will skip the email search since the owners name may be in there
        let contactEmail = contact.mail;
        delete contact['mail'];
        if (
          Object.keys(contact)
            .filter(
              // We will not search on null fields
              (key) => isset(contact, key, false) !== false
            )
            .filter(
              (key) =>
                typeof contact[key] !== 'object' &&
                typeof contact[key] !== 'boolean'
            )
            .filter(
              (
                key // Check each key for a matching value, return contact if true
              ) =>
                contact[key]
                  .toLowerCase()
                  .replace(',', '')
                  .replace(' ', '')
                  .includes(
                    this.state.searchValue
                      .toLowerCase()
                      .replace(',', '')
                      .replace(' ', '')
                  )
            ).length > 0 // If any keys are left
        ) {
          contact.mail = contactEmail;
          return contact;
        }
      })
      // Remove contacts that do not match the selected filters
      .filter((contact) => {
        let selectedFilters = this.state.selectedFilters;

        // If the user has not selected any filters
        if (Object.keys(selectedFilters).length === 0) {
          return contact;
        }

        // Loop through each selected filter
        if (
          Object.keys(selectedFilters)
            .filter(
              (filterKey) => isset(contact, filterKey, false) !== false // We will not search on null fields
            )
            .filter((filterKey) => {
              // If their value for this filter matches...
              // And they have a match for each selected filter (len = len)
              if (
                contact[filterKey].toLowerCase() ===
                selectedFilters[filterKey].toLowerCase()
              ) {
                return contact;
              }
            }).length === Object.keys(selectedFilters).length
        ) {
          // We return this contact as a match
          return contact;
        }
      })
      // Map the contacts to their photos
      .map((contact) => {
        const contactPhotoInfo = isset(
          this.state.contactPhotos,
          contact.id,
          'none'
        );

        // Add photo url from callback to contact here
        contact.imageUrl = contactPhotoInfo;

        return contact;
      })
      // Build the React framework for each resulting user
      .map((contact) => (
        <div key={contact.id} className='col'>
          {this.state.tenantName ? (
            // You may see warnings for nesting <a> tags but it all works fine
            <a
              href={
                'https://' +
                this.state.tenantName +
                '-my.sharepoint.com/_layouts/15/me.aspx/?p=' +
                contact.mail +
                '&v=work'
              }
              target='_blank'
              style={{ textDecoration: 'none' }}
            >
              <UserCard key={contact.id} user={contact} />
            </a>
          ) : (
            <UserCard key={contact.id} user={contact} />
          )}
        </div>
      ));

    if (usersToDisplay.length === 0) {
      // If we ran a search then we can show that no users were found
      if (
        this.state.searchValue !== '' ||
        Object.keys(this.state.selectedFilters).length !== 0
      ) {
        return (
          <BuildCard
            title='Unable To Find Any Users'
            subtitle='Try adjusting your search'
          />
        );
      } else {
        // Else, we are probably fetching the list of users
        return <BuildCard title='Fetching Users...' subtitle='' />;
      }
    } else {
      let numberOfColumnsForScreen = this.state.windowWidth > 1500 ? '4' : '3';

      return (
        <div
          className={
            'mt-15 row row-cols-1 row-cols-md-' +
            numberOfColumnsForScreen +
            ' g-4 m-1 mr-4'
          }
        >
          {usersToDisplay}
        </div>
      );
    }
  }

  handleFilterChange = (event = null, filter = null) => {
    // Get the current state of the filters
    let filters = this.state.filters;
    let selectedFilters = this.state.selectedFilters;

    if (event) {
      const parentElement =
        event.target.parentElement.parentElement.innerText.toLowerCase();
      var filter = '';
      // For each filter in our state, find which one was adjusted
      for (const [key, value] of Object.entries(filters)) {
        if (
          parentElement.includes(
            key
              .toLowerCase()
              .replace('availability', 'availabilit')
              .replace('office', '')
          )
        ) {
          filter = key;
          break;
        }
      }
    }

    // Set current selection to false, find clicked option and set selection to true
    let adjustedFilters = filters[filter].map((option) => {
      // Set current selection to false
      if (option.selected === true) {
        option.selected = false;
      }

      // If this is a 'Select All' option, remove that option for some reason that works.
      if (event && option.label.indexOf('All') > -1) {
        delete selectedFilters[filter];
      } else if (event && option.label === event.target.outerText) {
        // Set the selected option as selected
        option.selected = true;
        // Adjust the mapping for the selected object
        selectedFilters[filter] = option.label;
      } else if (option.label === selectedFilters[filter]) {
        option.selected = true;
        // Adjust the mapping for the selected object
        selectedFilters[filter] = option.label;
      } else {
        option.selected = false;
      }
      return option;
    });

    // Replace the state of the filter that was adjusted
    filters[filter] = adjustedFilters;

    this.clearSearch();
    this.resetPresenceFilter();
    // Modify the state
    this.setState({
      filters: filters,
      selectedFilters: selectedFilters,
    });
  };

  resetPresenceFilter() {
    // Get all unique presence
    let availabilities = WebApps.Arrays.getUniqueValues(
      WebApps.Arrays.makeSingleDem(this.state.contacts, 'availability')
    );
    let availabilityDropdownOptions = availabilities.map((availability) => {
      return {
        function: this.handleFilterChange,
        visible: true,
        key: availability,
        label: availability,
      };
    });

    this.setState({
      filters: {
        officeLocation: this.state.filters.officeLocation,
        department: this.state.filters.department,
        availability: [
          {
            function: this.handleFilterChange,
            visible: true,
            key: 'availability',
            label: 'All Availabilities',
            lock: true,
          },
        ].concat(availabilityDropdownOptions),
      },
    });
  }

  getQuickFilterOptions() {
    // Get all unique offices
    let officeLocations = WebApps.Arrays.removeNullValues(
      WebApps.Arrays.getUniqueValues(
        WebApps.Arrays.makeSingleDem(this.state.contacts, 'officeLocation')
      )
    );

    // Get all unique departments
    let departments = WebApps.Arrays.removeNullValues(
      WebApps.Arrays.getUniqueValues(
        WebApps.Arrays.makeSingleDem(this.state.contacts, 'department')
      )
    );

    let locationDropdownOptions = officeLocations.map((location) => {
      return {
        function: this.handleFilterChange,
        visible: true,
        key: location,
        label: location,
      };
    });

    let departmentDropdownOptions = departments.map((department) => {
      return {
        function: this.handleFilterChange,
        visible: true,
        key: department,
        label: department,
      };
    });

    this.setState({
      filters: {
        officeLocation: [
          {
            function: this.handleFilterChange,
            visible: true,
            key: 'officeLocation',
            lock: true,
            label: 'All Locations',
          },
        ].concat(locationDropdownOptions),
        department: [
          {
            function: this.handleFilterChange,
            visible: true,
            key: 'department',
            lock: true,
            label: 'All Departments',
          },
        ].concat(departmentDropdownOptions),
        availability: isset(this.state.filters, 'availability', []),
      },
    });
  }

  clearSearch() {
    document.getElementById('search-bar').value = '';
    this.setState({
      searchValue: '',
    });
  }

  clearFilters = () => {
    this.resetPresenceFilter();
    // Remove the selected filters
    this.setState({
      filters: {
        availability: ['presenceUnknown'],
        department: [],
        officeLocation: [],
      },
      selectedFilters: {},
    });

    // Reestablish the options to reset the labels
    this.getQuickFilterOptions();
  };

  saveFilters = () => {
    localStorage.setItem(
      'selectedFilters',
      JSON.stringify(this.state.selectedFilters)
    );
  };

  render() {
    return (
      <React.Fragment>
        <NavBar
          brand='Teams WhereRU'
          links={[
            {
              label: 'Table View',
              visible: true,
              path: '/table',
            },
            {
              label: 'Photo View',
              visible: true,
              path: '/photos',
            },
          ]}
          search={{
            enabled: true,
            function: this.handleSearchChange,
            button: false,
            filters: {
              enabled: false,
            },
          }}
        />

        {Object.keys(this.state.contactPresence).length > 0 ? (
          <center>
            {
              //Wait until state has been populated before rendering the filter buttons
              Object.keys(this.state.filters).map((filter) => {
                if (this.state.filters[filter].length > 2) {
                  return (
                    <BuildDropdown
                      key={filter}
                      options={this.state.filters[filter]}
                    />
                  );
                }
              })
            }
            <React.Fragment>
              <BuildButton
                function={this.clearFilters}
                label='Clear Filters'
                btnStyle='outline-secondary'
              />
              <BuildButton
                function={this.saveFilters}
                label='Save Filters'
                btnStyle='outline-success'
              />
              {this.generateUserCards()}
            </React.Fragment>
          </center>
        ) : (
          <BuildCard title='Fetching Users...' subtitle='' />
        )}
      </React.Fragment>
    );
  }
}

export default UserTiles;
