import React, { Component } from 'react';
import Tooltip from '../spa-master/assets/scripts/tooltip';
import WebApps from '../spa-master/assets/scripts/master';

class UserCard extends Component {
  render() {
    const {
      id,
      imageUrl,
      availability,
      presenceStyle,
      mail,
      displayName,
      businessPhones,
      jobTitle,
      message,
      statusMessage,
    } = this.props.user;

    var { pronouns, rate, hireDate } = this.props.user;

    const maxNameLengthLargeScreen = 26;
    const maxNameLengthSmallScreen = 23;

    const maxTitleLengthLargeScreen = 31;
    const maxTitleLengthSmallScreen = 28;

    const userImageTag = 
      <img
        alt={'Photo of ' + displayName}
        src={
          imageUrl === 'none'
            ? WebApps.Content.getIcon(
                'user-male-circle',
                40,
                true
              )
            : imageUrl
        }
      />

    return (
      <React.Fragment>
        <div
          className={'card m-1 shadow p-1 bg-white'}
          style={{
            height: 105,
            width: window.innerWidth > 1500 ? 400 : 375,
            borderWidth: 3,
            borderLeftWidth: 6,
            borderLeftColor: presenceStyle,
            borderTopColor: presenceStyle,
            textAlign: 'left',
          }}
          key={id}
        >
          <div className='row'>
            <div className='col-md-10 mr-3'>
              <div className='row'>
                <div
                  className={
                    'column ml-' +
                    (imageUrl === 'none' ? '3 mt-2 mr-4' : '2 mr-4 pl-1')
                  }
                >
                  {message ?
                    <Tooltip content={'Out of Office Message: ' + message}>
                        {userImageTag}
                        <img
                          alt='Broken'
                          style={{ position: 'absolute' }}
                          src={WebApps.Content.getIcon(
                            'exclamation-mark',
                            16,
                            true
                          )}
                        /> 
                    </Tooltip>: userImageTag}

                  {statusMessage ?
                    <Tooltip content={'Teams Status: ' + statusMessage}>
                        <img
                          alt='Broken'
                          style={{ position: 'absolute', paddingTop:"50%" }}
                          src={WebApps.Content.getIcon(
                            'exclamation-mark',
                            16,
                            true
                          )}
                        />
                    </Tooltip> : null}
                </div>
                <div className='column mt-2 ml-1' style={{lineHeight:"125%"}}>
                  <span
                    className='card-title'
                    title={displayName}
                    style={{ color: 'black' }}
                  >
                    {' '}
                    {displayName.length >
                    (window.innerWidth >= 1500
                      ? maxNameLengthLargeScreen
                      : maxNameLengthSmallScreen)
                      ? displayName.substring(
                          0,
                          window.innerWidth > 1500
                            ? maxNameLengthLargeScreen
                            : maxNameLengthSmallScreen
                        ) + '...'
                      : displayName}
                  </span>
                  <br></br>
                  <small className='text-muted' title={jobTitle}>
                    {jobTitle.length >
                    (window.innerWidth >= 1500
                      ? maxTitleLengthLargeScreen
                      : maxTitleLengthSmallScreen)
                      ? jobTitle.substring(
                          0,
                          window.innerWidth > 1500
                            ? maxTitleLengthLargeScreen
                            : maxTitleLengthSmallScreen
                        ) + '...'
                      : jobTitle}
                  </small>
                  <br></br>
                  <span style={{ fontSize: 11, color: presenceStyle }}>
                    {availability.replace('DoNotDisturb', 'NoDisturb')}{' '}
                  </span>

                  {pronouns ? (
                    <small className='text-muted ml-2'>
                      {'(' + pronouns + ')'}
                    </small>
                  ) : null}
                  {rate && rate > 0 ? (
                    <small
                      className='text-muted ml-2'
                      title={'($' + rate.split('.')[0] + '/hr)'}
                    >
                      {'$' + rate.split('.')[0] + '/hr'}
                    </small>
                  ) : null}
                  <br></br>
                  {hireDate ? (
                    <span style={{ fontSize: 11, color: 'black' }}>
                      {'Joined: ' + hireDate}{' '}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='col-md-1 mt-1'>
              <div className='row'>
                <div>
                  <a href={`sip:${mail}`}>
                    <img
                      alt='Send this user a message'
                      src={WebApps.Content.getIcon('filled-chat', 14, true)}
                    />
                  </a>
                </div>
                <div>
                  <a href={`mailto:${mail}`}>
                    <img
                      alt='Send this user an email'
                      src={WebApps.Content.getIcon('new-message', 14, true)}
                    />
                  </a>
                </div>
                <div>
                  <a href={`tel:${businessPhones[0]}`}>
                    <img
                      alt='Call this user'
                      src={WebApps.Content.getIcon(
                        'phone-disconnected',
                        14,
                        true
                      )}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserCard;
