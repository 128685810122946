import React from 'react';
import WebApps from '../../assets/scripts/master';

import $ from 'jquery';

const BuildDropdownButton = (options) => {
  // If there is a select All option available, we will display that by default
  let allLabelOptions = options.filter((option) =>
    option.label.includes('All')
  );

  // Filter list of options down to the option that has the selected key set to true
  let labelOptions = options.filter(
    (filter) => WebApps.Mappings.isset(filter, 'selected', false) === true
  );

  labelOptions =
    labelOptions.length === 0
      ? allLabelOptions.length === 0
        ? options[0]
        : allLabelOptions[0]
      : labelOptions[0];

  // If none were selected to be the label, then we either want an icon or a standard string
  return (
    <button
      className='btn btn-secondary dropdown-toggle'
      type='button'
      id='dropdownMenuButton'
      data-toggle='dropdown'
      aria-expanded='false'
      key={labelOptions.label}
    >
      {labelOptions.label}
    </button>
  );
};

const BuildDropdown = (props) => {
  // Find options to lock to the bottom of the dropdown
  const lockedOption = props.options.filter(
    (option) => WebApps.Mappings.isset(option, 'lock', false) === true
  )[0];

  // Remove any locked options from the array
  const unlockedOptions = props.options.filter(
    (option) => WebApps.Mappings.isset(option, 'lock', false) === false
  );

  // Sort the list
  const options = WebApps.Arrays.sortByKey(unlockedOptions, 'label');

  // Put the locked one at the bottom/end of the array
  options.push(lockedOption);

  return (
    <div className='btn-group mr-2 dropdown'>
      {BuildDropdownButton(options)}
      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
        {options
          .filter(
            (item) => WebApps.Mappings.isset(item, 'visible', true) === true
          )
          .map((item) => (
            <React.Fragment>
              {item.lock === true ? (
                <div className='dropdown-divider'></div>
              ) : null}
              <a
                onClick={(e) => {
                  item.function(e);
                  $('.dropdown-menu').trigger('click');
                }}
                key={item.key}
                className='dropdown-item'
              >
                {item.label}
              </a>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default BuildDropdown;
