import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

// Custom Components
import NavBar from './spa-master/components/archive/navbar';
import { authProvider } from './spa-master/assets/scripts/microsoft/authProvider';

import { AzureAD, LoginType, AuthenticationState } from 'react-aad-msal';

// Pages
import UserTiles from './pages/usertiles';
import UserTable from './pages/usertable';
import UserPhotos from './pages/userphotos';

import 'jquery/dist/jquery.min.js';

//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
class App extends Component {
  constructor(props) {
    super(props);

    // Change the login type to execute in a Redirect
    const options = authProvider.getProviderOptions();
    options.loginType = LoginType.Redirect;
    authProvider.setProviderOptions(options);
  }

  render() {
    return (
      <AzureAD provider={authProvider} forceLogin={true}>
        {({ login, logout, accountInfo, authenticationState, error }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return (
                <Router>
                  <Switch>
                    <Route
                      exact
                      path='/table'
                      render={(props) => <UserTable provider={authProvider} />}
                    />
                    <Route
                      path='/photos'
                      render={(props) => <UserPhotos provider={authProvider} />}
                    />
                    <Route
                      path='/'
                      render={(props) => <UserTiles provider={authProvider} />}
                    />
                  </Switch>
                </Router>
              );
            case AuthenticationState.Unauthenticated:
              return (
                <React.Fragment>
                  <NavBar brand='Teams WhereRU' />
                  <div className='col-large-6 m-2'>
                    <div className='card text-center'>
                      <div className='card-header'>Teams WhereRU</div>
                      <div className='card-body'>
                        <h5 className='card-title'>Please Sign In</h5>
                        <button
                          onClick={() => login()}
                          className='btn m-2 btn-primary'
                          type='submit'
                        >
                          Login
                        </button>
                      </div>
                      <div className='card-footer text-muted'>{'v1.0.0'}</div>
                    </div>
                  </div>
                </React.Fragment>
              );
            case AuthenticationState.InProgress:
              return (
                <center>
                  <div style={{ paddingTop: 100 }}>
                    <div
                      className='spinner-border'
                      role='status'
                      style={{ color: '#563d7c' }}
                    >
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                </center>
              );
            default:
              return <span>{error}</span>;
          }
        }}
      </AzureAD>
    );
  }
}

export default App;
