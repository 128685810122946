// docs: https://getbootstrap.com/docs/5.0/components/buttons/
import React from 'react';
import WebApps from '../../assets/scripts/master';

// Stateless Functional Component
const BuildButton = (props) => {
  const { isset } = WebApps.Mappings;
  props = {
    class: 'btn m-2 btn-',
    style: isset(
      props,
      'btnStyle',
      'outline-success'
    ) /* outline | primary, secondary, success, danger, warning, info, light, dark, link */,
    type: isset(props, 'type', 'submit') /* button, submit, reset */,
    label: isset(props, 'label', 'Submit'),
    size: 'btn-' + isset(props, 'size', 'Search') /* btn-sm, lg */,
    state: isset(props, 'state', 'Search') /* enabled, disabled*/,
    align: isset(props, 'align', null) /* right - justify-content-md-end */,
    function: isset(props, 'function', null),
    value: isset(props, 'value', null),
  };

  switch (props.type) {
    case ' ':
    default:
      return (
        <button
          value={props.value}
          onClick={(e) => props.function(props.value ? props.value : e)}
          className={
            props.class + props.style + ' ' + props.size + ' ' + props.state
          }
          type={props.type}
        >
          {props.label}
        </button>
      );
  }
};
export default BuildButton;
