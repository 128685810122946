import $ from 'jquery';
import 'datatables.net';
import React from 'react';

const WebApps = {
  Mappings: {
    isset: function (dictionary, key, onEmpty = null) {
      if (typeof dictionary[key] !== 'undefined') {
        if (dictionary[key] !== null) {
          return dictionary[key];
        }
      }
      return onEmpty;
    },
  },
  URLs: {
    thisURL: function () {
      return window.location.href;
    },
    redirect: function (url, state = null) {
      if (state) {
        WebApps.Sessions.updateUserSession(state).then(
          () => (window.location.href = url)
        );
      } else window.location.href = url;
    },
  },
  Sessions: {
    updateUserSession: function (state) {
      // Remove Portal Access From External Store as this should be refreshed everytime
      delete state['PORTAL_ACCESS'];
      delete state['PAGE_OPTIONS'];
      delete state['IS_AUTHORIZED'];
      delete state['create_auth_auth'];

      /**
       * Whenever we leave the page/refresh we lose the current state, which may contain important information
       * Without this, anytime we use a link, all the state is lost, so we save it securely, externaly here before the redirect
       */
      return WebApps.Requests.makeApiRequest(
        window.apigClientFactory.newClient().usersUserIdSessionPost,
        { userId: localStorage.getItem('username') },
        {
          state: JSON.stringify(state),
        }
      );
    },
    logOut: function () {
      WebApps.Requests.makeApiRequest(
        window.apigClientFactory.newClient().usersUserIdSessionDelete,
        { userId: localStorage.getItem('idpUsername') },
        { empty: 'body' }
      ).then(() =>
        WebApps.Requests.makeApiRequest(
          window.apigClientFactory.newClient().authSignOutPost,
          null,
          { email: localStorage.getItem('email') }
        )
          .then(() => {
            WebApps.Client.clearAuthCookies();
            localStorage.clear();
          })
          .then(() => (window.location.href = '/'))
      );
    },
  },
  Client: {
    setAuthCookies: function (
      app_client_id,
      cognito_user_id,
      id_token,
      access_token,
      refresh_token = null
    ) {
      var expires = new Date(Date.now() + 2592000);

      window.cookies.set(
        'CognitoIdentityServiceProvider.' +
          app_client_id +
          '.' +
          cognito_user_id +
          '.idToken',
        id_token,
        { path: '/', expires: expires }
      );
      window.cookies.set(
        'CognitoIdentityServiceProvider.' +
          app_client_id +
          '.' +
          cognito_user_id +
          '.accessToken',
        access_token,
        { path: '/', expires: expires }
      );

      if (refresh_token) {
        window.cookies.set(
          'CognitoIdentityServiceProvider.' +
            app_client_id +
            '.' +
            cognito_user_id +
            '.refreshToken',
          refresh_token,
          { path: '/', expires: expires }
        );

        window.cookies.set(
          'CognitoIdentityServiceProvider.' +
            app_client_id +
            '.' +
            cognito_user_id +
            '.refreshToken.expires',
          expires.getTime(),
          { path: '/', expires: expires }
        );
      }
    },
    clearAuthCookies: function () {
      var cookies = window.cookies.getAll();

      Object.keys(cookies).forEach((name) => {
        if (name.includes('CognitoIdentityServiceProvider')) {
          window.cookies.remove(name);
        }
      });
    },
  },
  Validation: {
    validateEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  Time: {
    relativeToNow: function (epochTs) {
      var currentEpoch = new Date().getTime() / 1000;
      var tsDiff =
        epochTs > currentEpoch
          ? epochTs - currentEpoch
          : currentEpoch - epochTs;

      var intervals = {};
      intervals[1] = ['Year', 31556926];
      intervals[tsDiff < 31556926 ? 1 : 0] = ['Month', 2628000];
      intervals[tsDiff < 2629744 ? 1 : 0] = ['Week', 604800];
      intervals[tsDiff < 604800 ? 1 : 0] = ['Day', 86400];
      intervals[tsDiff < 86400 ? 1 : 0] = ['Hour', 3600];
      intervals[tsDiff < 3600 ? 1 : 0] = ['Minute', 60];
      intervals[tsDiff < 60 ? 1 : 0] = ['Second', 1];

      var diffValue = Math.floor(tsDiff / intervals[1][1]);
      var diffString =
        diffValue + ' ' + intervals[1][0] + (diffValue > 1 ? 's' : '');

      return epochTs > currentEpoch ? 'in ' + diffString : diffString + ' Ago';
    },
  },
  Notifications: {
    getMessageFromCode: function (code) {
      var codes = {
        BLANK: '{0}',
        INVALID_FORM_DATA: 'The {0} You Submitted Was Invalid',
        SUCCESSFUL_ACTION: '{0} {1} Successfully',
        RETRIEVAL_FAILURE: 'Failed to Retrieve {0}',
        SUCCESSFUL_CRON_EXECUTION: '',
        ERROR_OCCURRED:
          'Your {0} Request Could Not be Completed - Please Try Again',
      };

      return codes[code];
    },
  },
  Content: {
    getIcon: function (key, size, urlOnly = false, color = '000000') {
      var iconUrl =
        'https://img.icons8.com/color/' + size * 2 + '/' + color + '/' + key + '.png';
      return urlOnly === true
        ? iconUrl
        : '<img src="' + iconUrl + '" height="' + size + '">';
    },
    getSpinner: function (height = 24, width = 24) {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={height.toString()}
          height={width.toString()}
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='feather feather-loader spin ml-2'
        >
          <line x1='12' y1='2' x2='12' y2='6'></line>
          <line x1='12' y1='18' x2='12' y2='22'></line>
          <line x1='4.93' y1='4.93' x2='7.76' y2='7.76'></line>
          <line x1='16.24' y1='16.24' x2='19.07' y2='19.07'></line>
          <line x1='2' y1='12' x2='6' y2='12'></line>
          <line x1='18' y1='12' x2='22' y2='12'></line>
          <line x1='4.93' y1='19.07' x2='7.76' y2='16.24'></line>
          <line x1='16.24' y1='7.76' x2='19.07' y2='4.93'></line>
        </svg>
      );
    },
  },
  Arrays: {
    makeSingleDem: function (array, key, subArray = false) {
      let singleDemArray = [];

      array.map((element) => {
        if (subArray) {
          element[key].map((subElement) => {
            // Add some identifier for grouping later
            subElement[subArray.parentKey] = subElement[subArray.parentKey];
            singleDemArray.push(subElement);
          });
        } else {
          singleDemArray.push(element[key]);
        }
      });

      return singleDemArray;
    },
    filterByKey: function (array, key) {
      return array.filter(
        (element) => WebApps.Mappings.isset(element, key, false) !== false
      );
    },
    sortByKey: function (array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    getUniqueValues: function (array) {
      return array.filter((v, i, a) => a.indexOf(v) === i);
    },
    removeNullValues: function (array) {
      return array.filter((el) => el !== null);
    },
    subArray: function (array, start = 0, stop = null) {
      var result = [];
      array.forEach((element, idx) => {
        if (idx >= start) result.push(array[idx]);
      });

      return result;
    },
    rekey: function (itemList, targetKey) {
      let dictToReturn = {};
      targetKey = targetKey.includes('/') ? targetKey.split('/') : targetKey;

      itemList.map((item) => {
        if (typeof targetKey === 'string') {
          dictToReturn[String(item[targetKey])] = item;
        } else {
          dictToReturn[String(item[targetKey[0]][targetKey[1]])] = item;
        }
      });

      return dictToReturn;
    },
  },

  Datatables: {
    /**
     * columns
     * recordType
     */
    buildTable: function (tableId, options, dataGetFn) {
      /**
       * Adds the necessary classes for the desired alignment.
       */
      $.each(options['columns'], function (key, values) {
        if (!('align' in values)) values['align'] = 'left';

        options['columns'][key]['className'] = 'dt-head-' + values['align'];
        options['columns'][key]['className'] += ' dt-body-' + values['align'];
      });

      if (!('paging' in options)) options['paging'] = true;
      if (!('buttons' in options)) options['buttons'] = true;
      if (!('searching' in options)) options['searching'] = true;
      if (!('search' in options)) options['search'] = {};
      if (!('order' in options)) options['order'] = [];

      $('#' + tableId)
        .dataTable({
          language: {
            aria: {
              sortAscending: ': activate to sort column ascending',
              sortDescending: ': activate to sort column descending',
            },
            emptyTable: 'No ' + options['recordType'],
            info:
              'Showing _START_ to _END_ of _TOTAL_ ' + options['recordType'],
            infoEmpty: 'No ' + options['recordType'] + ' Found',
            infoFiltered: '',
            lengthMenu: '_MENU_ Records',
            search: '',
            loadingRecords: '&nbsp;',
            processing: 'Fetching ' + options['recordType'] + '...',
            searchPlaceholder: 'Search...',
            zeroRecords:
              'No ' + options['recordType'] + ' Matched the Search Criteria',
          },
          processing: true,
          columns: options['columns'],
          buttons:
            options['buttons'] === false
              ? []
              : [
                  { extend: 'print', className: 'btn blue btn-outline' },
                  {
                    extend: 'pdf',
                    className: 'btn red btn-outline',
                    orientation: 'landscape',
                  },
                  {
                    extend: 'csv',
                    className: 'btn green btn-outline',
                    text: 'Excel',
                  },
                  {
                    extend: 'colvis',
                    className: 'btn dark btn-outline',
                    text: 'Cols.',
                  },
                ],
          ajax: function (data, callback, settings) {
            dataGetFn(function (result) {
              callback({
                data: WebApps.Datatables.prepareRows(
                  result,
                  WebApps.Arrays.makeSingleDem(options['columns'], 'source')
                ),
              });
            });
          },
          paging: true,
          ordering: true,
          search: options['search'],
          searching: options['searching'],
          responsive: false,
          colReorder: {
            reorderCallback: function () {
              console.log('callback');
            },
          },
          footerCallback: function (row, data, start, end, display) {
            // Removes Any Formatting to Retrieve Integer Data
            var intVal = function (i) {
              return typeof i === 'string'
                ? i.replace(/[\$,]/g, '') * 1
                : typeof i === 'number'
                ? i
                : 0;
            };
          },
          order: options['order'],
          lengthMenu: [
            [10, 25, 50, 100, '-1'],
            [10, 25, 50, 100, 'All'],
          ],
          pageLength: 25,
          dom: "<'row' <'col-md-12'B>><'row'<'col-md-6 col-sm-12'l><'col-md-6 col-sm-12'f>r><'table-scrollable't><'row'<'col-md-5 col-sm-12'i><'col-md-7 col-sm-12'p>>",
        })
        .addClass('table table-striped table-bordered table-hover');

      return true;
    },

    prepareRows: function (dataset, rowsToKeep) {
      var newDataset = [];

      $.each(dataset, function (dataIndex, dataArray) {
        var newRowData = [];

        $.each(rowsToKeep, function (columnKey, columnValue) {
          if (columnValue.indexOf('/') > -1) {
            var columnValuePieces = columnValue.split('/');
            dataArray[columnValue] =
              dataArray[columnValuePieces[0]][columnValuePieces[1]];
          }

          newRowData.push(dataArray[columnValue]);
        });

        newDataset.push(newRowData);
      });

      return newDataset;
    },
    refreshTable: function (tableToRefresh) {
      $('#' + tableToRefresh + '-table')
        .DataTable()
        .ajax.reload();
    },
  },

  Requests: {
    makeApiRequest: function (
      sdkFunction,
      params = null,
      body = null,
      addParams = {}
    ) {
      addParams['headers'] = {
        authorization: localStorage.getItem('apiKey'),
        'x-session':
          localStorage.getItem('partnerId') +
          ':' +
          localStorage.getItem('clientId') +
          ':' +
          localStorage.getItem('userId'),
      };

      return sdkFunction(params, body, addParams).then((response) => {
        if (WebApps.Mappings.isset(response, 'status', 0) === 401) {
          console.log('Your token has expired, please log in again');
          window.location.href = '/controller?action=log_out';
        }

        if (
          response.config.url.split('v1')[1] !== '/users/audits' &&
          localStorage.getItem('apiKey') !== null
        ) {
          WebApps.Requests.makeApiRequest(
            window.apigClientFactory.newClient().usersAuditsPost,
            null,
            {
              requestUrl: response.config.url.split('v1')[1],
              statusCode: response.status,
            }
          );
        }

        return response;
      });
    },
    handleErrors: function (response) {
      if (!response.ok) {
        console.log(response);
        throw Error(response.statusText);
      }
      return response;
    },
  },

  Strings: {
    removePunctuation: function (string) {
      return string
        .replaceAll(/[.,\/#!$%\^&\*;:{}=\-_`~()?"¿¡']/g, '')
        .replaceAll(/\s{2,}/g, ' ')
        .toLowerCase();
    },

    removeAccents: function (string) {
      return string.normalize('NFD').replaceAll(/[\u0300-\u036f]/g, '');
    },

    ucword(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    sanitizeWord: function (string) {
      if (string == null) return '';
      if (string.length === 0) return '';
      string = WebApps.Strings.removeAccents(string);
      string = WebApps.Strings.removePunctuation(string);
      return string;
    },

    getRandomString: function (length) {
      var result = [];
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      return result.join('');
    },

    maskText: function (text, opts) {
      var textLength = text.length;

      const side = WebApps.Mappings.isset(opts, 'side', 'left');
      const char = WebApps.Mappings.isset(opts, 'char', '*');
      const numChars = WebApps.Mappings.isset(opts, 'numChars', 4);
      const mask = char.repeat(textLength - numChars);

      if (side === 'left') {
        return mask + text.substring(textLength - numChars);
      } else {
        return text.substring(0, numChars) + mask;
      }
    },
  },
  StaticArrays: {
    getCountOptions: function (max = 100) {
      var countArray = [];
      for (let step = 1; step < max; step++) {
        countArray.push({
          num: step,
        });
      }

      return countArray;
    },
    getCanadianProvinces: function () {
      return [
        { abbr: 'AB', name: 'Alberta' },
        { abbr: 'BC', name: 'British Columbia' },
        { abbr: 'MB', name: 'Manitoba' },
        { abbr: 'NB', name: 'New Brunswick' },
        { abbr: 'NL', name: 'Newfoundland and Labrador' },
        { abbr: 'NT', name: 'Northwest Territories' },
        { abbr: 'NS', name: 'Nova Scotia' },
        { abbr: 'NU', name: 'Nunavut' },
        { abbr: 'ON', name: 'Ontario' },
        { abbr: 'PE', name: 'Prince Edward Island' },
        { abbr: 'QC', name: 'Quebec' },
        { abbr: 'SK', name: 'Saskatchewan' },
        { abbr: 'YT', name: 'Yukon' },
      ];
    },
    getStates: function () {
      return [
        { abbr: 'AL', name: 'Alabama' },
        { abbr: 'AK', name: 'Alaska' },
        { abbr: 'AZ', name: 'Arizona' },
        { abbr: 'AR', name: 'Arkansas' },
        { abbr: 'CA', name: 'California' },
        { abbr: 'CO', name: 'Colorado' },
        { abbr: 'CT', name: 'Connecticut' },
        { abbr: 'DE', name: 'Delaware' },
        { abbr: 'DC', name: 'District Of Columbia' },
        { abbr: 'FL', name: 'Florida' },
        { abbr: 'GA', name: 'Georgia' },
        { abbr: 'HI', name: 'Hawaii' },
        { abbr: 'ID', name: 'Idaho' },
        { abbr: 'IL', name: 'Illinois' },
        { abbr: 'IN', name: 'Indiana' },
        { abbr: 'IA', name: 'Iowa' },
        { abbr: 'KS', name: 'Kansas' },
        { abbr: 'KY', name: 'Kentucky' },
        { abbr: 'LA', name: 'Louisiana' },
        { abbr: 'ME', name: 'Maine' },
        { abbr: 'MD', name: 'Maryland' },
        { abbr: 'MA', name: 'Massachusetts' },
        { abbr: 'MI', name: 'Michigan' },
        { abbr: 'MN', name: 'Minnesota' },
        { abbr: 'MS', name: 'Mississippi' },
        { abbr: 'MO', name: 'Missouri' },
        { abbr: 'MT', name: 'Montana' },
        { abbr: 'NE', name: 'Nebraska' },
        { abbr: 'NV', name: 'Nevada' },
        { abbr: 'NH', name: 'New Hampshire' },
        { abbr: 'NJ', name: 'New Jersey' },
        { abbr: 'NM', name: 'New Mexico' },
        { abbr: 'NY', name: 'New York' },
        { abbr: 'NC', name: 'North Carolina' },
        { abbr: 'ND', name: 'North Dakota' },
        { abbr: 'OH', name: 'Ohio' },
        { abbr: 'OK', name: 'Oklahoma' },
        { abbr: 'OR', name: 'Oregon' },
        { abbr: 'PA', name: 'Pennsylvania' },
        { abbr: 'RI', name: 'Rhode Island' },
        { abbr: 'SC', name: 'South Carolina' },
        { abbr: 'SD', name: 'South Dakota' },
        { abbr: 'TN', name: 'Tennessee' },
        { abbr: 'TX', name: 'Texas' },
        { abbr: 'UT', name: 'Utah' },
        { abbr: 'VT', name: 'Vermont' },
        { abbr: 'VA', name: 'Virginia' },
        { abbr: 'WA', name: 'Washington' },
        { abbr: 'WV', name: 'West Virginia' },
        { abbr: 'WI', name: 'Wisconsin' },
        { abbr: 'WY', name: 'Wyoming' },
      ];
    },
    getMarkets: function () {
      return [
        { id: 1, name: 'Financial Services' },
        { id: 48, name: 'Law firm' },
        { id: 32, name: 'Healthcare, Pharma & Biotech' },
        { id: 50, name: 'Government' },
      ];
    },
  },
  Actions: {
    print: function (htmlToPrint) {
      var w = window.open();

      var html = '<!DOCTYPE HTML>';
      html += '<html lang="en-us">';
      html += '<head><style></style></head>';
      html += '<body>';

      html += htmlToPrint;

      html += '</body>';
      w.document.write(html);
      w.window.print();
      w.document.close();
      w.window.close();
    },
  },

  Forms: {
    getSubmissionLink: function (
      section,
      action,
      params = {},
      trueRedirect = null
    ) {
      return (
        '/controller?' +
        $.param(
          $.extend(params, {
            controller: {
              action: action,
              section: section,
              success_redirect: trueRedirect ? trueRedirect : '/',
            },
          })
        )
      );
    },
    buildOptionArray: function (
      sourceArr,
      valueKey,
      textConstr,
      groupKey = null
    ) {
      var optionArray = [];

      // If There Aren't Any Options to Prepare, Return a Blank Array
      if (sourceArr.length === 0) return [];

      sourceArr.forEach(function (element) {
        var valueText = '';

        textConstr.forEach(function (textOption) {
          if ('key' in textOption) {
            if ('prepend' in textOption) valueText += textOption['prepend'];

            if (textOption['key'].includes('/') === true) {
              var textKeyParts = textOption['key'].split('/');

              if (textKeyParts.length === 2) {
                valueText += element[textKeyParts[0]][textKeyParts[1]];
              } else if (textKeyParts.length === 3) {
                valueText +=
                  element[textKeyParts[0]][textKeyParts[1]][textKeyParts[2]];
              }
            } else valueText += element[textOption['key']];

            if ('append' in textOption) valueText += textOption['append'];
          }
        });

        optionArray[element[valueKey]] = valueText;
      });

      return optionArray;
    },

    arrayToOptions: function (
      optionsArr,
      emptyText = 'Select One...',
      selectedOptions = [],
      disabledOptions = []
    ) {
      // If There's At Least 1 Option
      if (Object.keys(optionsArr).length > 0) {
        if (
          Array.isArray(Object.keys(optionsArr)[0]) ||
          typeof optionsArr[Object.keys(optionsArr)[0]] === 'object'
        ) {
          for (let [optGroupName, options] of Object.entries(optionsArr)) {
            return (
              <React.Fragment>
                {emptyText ? <option value=''> + {emptyText} + </option> : ''}
                <optgroup label={optGroupName}>
                  {WebApps.Forms.buildOptions(
                    options,
                    selectedOptions,
                    disabledOptions
                  )}
                </optgroup>
              </React.Fragment>
            );
          }
        } else
          return (
            <React.Fragment>
              {emptyText ? <option value=''> + {emptyText} + </option> : ''}
              {WebApps.Forms.buildOptions(
                optionsArr,
                selectedOptions,
                disabledOptions
              )}
            </React.Fragment>
          );
      }
    },

    buildOptions: function (
      optionsArr,
      selectedOptions = [],
      disabledOptions = []
    ) {
      return Object.entries(optionsArr).map((value, text) => (
        <option value={value}>{text}</option>
      ));
      /*var attributes =
          disabledOptions.includes(value.toString()) ||
          disabledOptions.includes(parseInt(value))
            ? 'disabled="disabled"'
            : '';
        attributes +=
          selectedOptions.includes(value.toString()) ||
          selectedOptions.includes(parseInt(value))
            ? ' selected="selected"'
            : '';
        */
    },
    getFormOptions: function (
      optionsArray,
      defaultValue = null,
      emptyText = 'Select One...',
      selectedOptions = [],
      disabledOptions = []
    ) {
      var optionText = emptyText ? <option value=''>{emptyText}</option> : null;

      if (optionsArray) {
        /*if (Array.isArray(optionsArray[Object.keys()[0]])) {
          return Object.keys(optionsArray).map((option) => {
            <optgroup label={option}>
              {WebApps.Forms.buildOptions(optionsArray[option])}
            </optgroup>;
          });
        } else {*/
        return WebApps.Forms.buildOptions(optionsArray);
        //}
      }
    },
    handleInputChanges: function () {},
    clearInput: function (inputId, callback = null) {
      document.getElementById(inputId).value = '';
      inputId = inputId.replace('-', '_');

      if (callback) {
        callback({ inputId: '' });
      }
    },
  },
};

export default WebApps;
