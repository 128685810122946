import React, { Component } from 'react';
import BuildCard from '../spa-master/components/archive/card';
import WebApps from '../spa-master/assets/scripts/master';
import NavBar from '../spa-master/components/archive/navbar';
import BuildButton from '../spa-master/components/archive/button';
import BuildDropdown from '../spa-master/components/archive/dropdown';

import {
  getUsersByGroup,
  getUserPhoto,
} from '../spa-master/assets/scripts/microsoft/graph-aad';

const { isset } = WebApps.Mappings;

class UserPhotos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      provider: props.provider,
      contacts: [],
      contactPhotos: {},
      searchValue: '',
      selectedFilters: {},
      windowWidth: window.innerWidth,
      filters: {
        availability: ['presenceUnknown'],
        department: [],
        officeLocation: [],
      },
    };
  }

  componentDidMount() {
    // Wait 3 seconds before attempting to get users
    setTimeout(
      () => {
        getUsersByGroup(this.state.provider, 'staff', this.handleGraphCallback)
      },
      2000
    );
  }

  getSavedFilters() {
    var selectedFilters = localStorage.getItem('selectedFilters');

    if (selectedFilters === null) {
      localStorage.setItem('selectedFilters', JSON.stringify({}));
    } else {
      //The state of selectedFilters needs to be set before calling 'handleFilterChange' since it will use values from the state variable.
      this.setState({
        selectedFilters: JSON.parse(selectedFilters),
      });

      Object.keys((selectedFilters = JSON.parse(selectedFilters))).forEach(
        (filter) => {
          this.handleFilterChange(null, filter);
        }
      );
    }
  }

  // Function passed through to the searchbar component in the navbar
  handleSearchChange = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  handleGraphCallback = (data, endpoint, callbackArgs = null) => {
    if (isset(data, 'error')) {
      console.log('GRAPH API ERROR: ', data['error']);
    } else {
      // We want the singular identifier for this endpoint
      let splitEndpoint = endpoint
        .replace('https://graph.microsoft.com/v1.0/', '')
        .split('/');

      switch (splitEndpoint[splitEndpoint.length - 1].split('?')[0]) {
        case 'users':
        case 'members':
          // Filtering 'users' without a job title
          let contacts = data.value.filter(
            (contact) =>
              contact.jobTitle !== null && contact.accountEnabled !== null
          );

          this.setState({
            contacts: WebApps.Arrays.sortByKey(this.state.contacts.concat(contacts), 'displayName'),
          });

          this.getAllUserPhotos();
          this.getQuickFilterOptions();
          break;

        case '$value':
          // If a user does not have the requested photo size avaible,
          // we are unable to display and will need to make another call
          if (data.type !== 'image/jpeg') {
            break;
          }
          let urlCreator = window.URL || window.webkitURL;
          let imageUrl = urlCreator.createObjectURL(data);

          // Store copy of current state
          let contactPhotoMap = this.state.contactPhotos;

          // Add  user photos using the id as the key
          contactPhotoMap[callbackArgs.userId] = imageUrl;

          // Update the state
          this.setState({
            contactPhotos: contactPhotoMap,
          });

          break;
      }
    }
  };

  getAllUserPhotos = () => {
    this.state.contacts.map((contact) =>
      getUserPhoto(
        this.state.provider,
        this.handleGraphCallback,
        contact.id,
        '240x240'
      )
    );
  };

  // We are going to filter through every field that is returned from the Graph api assuming we will do pre filtering at the query level
  generatePhotoCards() {
    let usersToDisplay = isset(this.state, 'contacts', [])
      // Remove contacts that do not have a field value that matches
      .filter((contact) => {
        // We will skip the email search since the owners name may be in there
        let contactEmail = contact.mail;
        delete contact['mail'];
        if (
          Object.keys(contact)
            .filter(
              // We will not search on null fields
              (key) => isset(contact, key, false) !== false
            )
            .filter(
              (key) =>
                typeof contact[key] !== 'object' &&
                typeof contact[key] !== 'boolean'
            )
            .filter(
              (
                key // Check each key for a matching value, return contact if true
              ) =>
                contact[key]
                  .toLowerCase()
                  .replace(',', '')
                  .replace(' ', '')
                  .includes(
                    this.state.searchValue
                      .toLowerCase()
                      .replace(',', '')
                      .replace(' ', '')
                  )
            ).length > 0 // If any keys are left
        ) {
          contact.mail = contactEmail;
          return contact;
        }
      })
      // Remove contacts that do not match the selected filters
      .filter((contact) => {
        let selectedFilters = this.state.selectedFilters;

        // If the user has not selected any filters
        if (Object.keys(selectedFilters).length === 0) {
          return contact;
        }

        // Loop through each selected filter
        if (
          Object.keys(selectedFilters)
            .filter(
              (filterKey) => isset(contact, filterKey, false) !== false // We will not search on null fields
            )
            .filter((filterKey) => {
              // If their value for this filter matches...
              // And they have a match for each selected filter (len = len)
              if (
                contact[filterKey].toLowerCase() ===
                selectedFilters[filterKey].toLowerCase()
              ) {
                return contact;
              }
            }).length === Object.keys(selectedFilters).length
        ) {
          // We return this contact as a match
          return contact;
        }
      })
      // Map the contacts to their photos
      .map((contact) => {
        const contactPhotoInfo = isset(
          this.state.contactPhotos,
          contact.id,
          'none'
        );

        // Add photo url from callback to contact here
        contact.imageUrl = contactPhotoInfo;

        return contact;
      })
      // Build the React framework for each resulting user
      .map((contact) => (
        <img
          alt={'Photo of ' + contact.displayName}
          title={contact.displayName}
          src={
            contact.imageUrl === 'none'
              ? WebApps.Content.getIcon('user-male-circle', 120, true)
              : contact.imageUrl
          }
        />
      ));

    if (usersToDisplay.length === 0) {
      // If we ran a search then we can show that no users were found
      if (this.state.searchValue !== '') {
        return (
          <BuildCard
            title='Unable To Find Any Users'
            subtitle='Try adjusting your search'
          />
        );
      } else {
        // Else, we are probably fetching the list of users
        return <BuildCard title='Fetching Users...' subtitle='' />;
      }
    } else {
      return <div>{usersToDisplay}</div>;
    }
  }

  clearSearch() {
    document.getElementById('search-bar').value = '';
    this.setState({
      searchValue: '',
    });
  }

  clearFilters = () => {
    // Remove the selected filters
    this.setState({
      filters: {
        availability: ['presenceUnknown'],
        department: [],
        officeLocation: [],
      },
      selectedFilters: {},
    });

    // Reestablish the options to reset the labels
    this.getQuickFilterOptions();
  };

  saveFilters = () => {
    localStorage.setItem(
      'selectedFilters',
      JSON.stringify(this.state.selectedFilters)
    );
  };

  handleFilterChange = (event = null, filter = null) => {
    // Get the current state of the filters
    let filters = this.state.filters;
    let selectedFilters = this.state.selectedFilters;

    if (event) {
      const parentElement =
        event.target.parentElement.parentElement.innerText.toLowerCase();
      var filter = '';
      // For each filter in our state, find which one was adjusted
      for (const [key, value] of Object.entries(filters)) {
        if (
          parentElement.includes(
            key
              .toLowerCase()
              .replace('availability', 'availabilit')
              .replace('office', '')
          )
        ) {
          filter = key;
          break;
        }
      }
    }

    // Set current selection to false, find clicked option and set selection to true
    let adjustedFilters = filters[filter].map((option) => {
      // Set current selection to false
      if (option.selected === true) {
        option.selected = false;
      }

      // If this is a 'Select All' option, remove that option for some reason that works.
      if (event && option.label.indexOf('All') > -1) {
        delete selectedFilters[filter];
      } else if (event && option.label === event.target.outerText) {
        // Set the selected option as selected
        option.selected = true;
        // Adjust the mapping for the selected object
        selectedFilters[filter] = option.label;
      } else if (option.label === selectedFilters[filter]) {
        option.selected = true;
        // Adjust the mapping for the selected object
        selectedFilters[filter] = option.label;
      } else {
        option.selected = false;
      }
      return option;
    });

    // Replace the state of the filter that was adjusted
    filters[filter] = adjustedFilters;

    this.clearSearch();
    // Modify the state
    this.setState({
      filters: filters,
      selectedFilters: selectedFilters,
    });
  };

  getQuickFilterOptions() {
    // Get all unique offices
    let officeLocations = WebApps.Arrays.removeNullValues(
      WebApps.Arrays.getUniqueValues(
        WebApps.Arrays.makeSingleDem(this.state.contacts, 'officeLocation')
      )
    );

    // Get all unique departments
    let departments = WebApps.Arrays.removeNullValues(
      WebApps.Arrays.getUniqueValues(
        WebApps.Arrays.makeSingleDem(this.state.contacts, 'department')
      )
    );

    let locationDropdownOptions = officeLocations.map((location) => {
      return {
        function: this.handleFilterChange,
        visible: true,
        key: location,
        label: location,
      };
    });

    let departmentDropdownOptions = departments.map((department) => {
      return {
        function: this.handleFilterChange,
        visible: true,
        key: department,
        label: department,
      };
    });

    this.setState({
      filters: {
        officeLocation: [
          {
            function: this.handleFilterChange,
            visible: true,
            key: 'officeLocation',
            lock: true,
            label: 'All Locations',
          },
        ].concat(locationDropdownOptions),
        department: [
          {
            function: this.handleFilterChange,
            visible: true,
            key: 'department',
            lock: true,
            label: 'All Departments',
          },
        ].concat(departmentDropdownOptions),
        availability: isset(this.state.filters, 'availability', []),
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <NavBar
          brand='Teams WhereRU'
          links={[
            {
              label: 'Table View',
              visible: true,
              path: '/table',
            },
            {
              label: 'Tile View',
              visible: true,
              path: '/tile',
            },
          ]}
          search={{
            enabled: true,
            function: this.handleSearchChange,
            button: false,
            filters: {
              enabled: false,
            },
          }}
        />

        {Object.keys(this.state.contactPhotos).length > 0 ? (
          <center>
            {
              //Wait until state has been populated before rendering the filter buttons
              Object.keys(this.state.filters).map((filter) => {
                if (this.state.filters[filter].length > 2) {
                  return (
                    <BuildDropdown
                      key={filter}
                      options={this.state.filters[filter]}
                    />
                  );
                }
              })
            }
            <React.Fragment>
              <BuildButton
                function={this.clearFilters}
                label='Clear Filters'
                btnStyle='outline-secondary'
              />
              {this.generatePhotoCards()}
            </React.Fragment>
          </center>
        ) : (
          <BuildCard title='Fetching Users...' subtitle='' />
        )}
      </React.Fragment>
    );
  }
}

export default UserPhotos;
