import graphConfig from './graphConfig';
import WebApps from '../master';

// Helper function to call MS Graph API endpoint
// using authorization bearer token scheme
export function callMSGraph(
  method,
  endpoint,
  token,
  callback,
  payload = null,
  callbackArgs = null
) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append('Authorization', bearer);

  const options = {
    method: method,
    headers: headers,
  };

  if (method === 'POST') {
    options.body = JSON.stringify(payload);
    headers.append('Content-Type', 'application/json');
  }
  fetch(endpoint, options)
    .then((response) => {
      if (endpoint.includes('photo')) {
        return response.blob();
      } else {
        return response.json();
      }
    })
    .then((response) => callback(response, endpoint, callbackArgs))
    .catch((error) => console.log('Failed to make graph API call', error));
}

export function getTenantName(provider, callback) {
  provider
    .getAccessToken()
    .then((token) => {
      callMSGraph(
        'GET',
        graphConfig.graphOrganizationEndpoint + '?$select=verifiedDomains',
        token.accessToken,
        callback
      );
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getUsersByGroup(provider, group, callback) {
  // Silently acquires an access token which is then attached to a request for Microsoft Graph data

  provider
    .getAccessToken()
    .then((token) => {
      callMSGraph(
        'GET',
        graphConfig.graphGroupsEndpoint + "?$select=id&$filter=displayName eq '"+ group + "'",
        token.accessToken,
        function (response, a, b) {
          var azCustomFields = ',extension_b2a84385320b4220a86caa54eb680c34_wruHireDate,extension_b2a84385320b4220a86caa54eb680c34_wruRate,extension_b2a84385320b4220a86caa54eb680c34_wruSchedule,extension_b2a84385320b4220a86caa54eb680c34_wruPronouns';

          var params =
            '?$select=businessPhones,displayName,givenName,id,jobTitle,mail,officeLocation,department,accountEnabled' +
            azCustomFields +
            '&$top=999';

          var uri =
            graphConfig.graphUsersEndpoint + params + '&$orderBy=displayName';

          if (WebApps.Mappings.isset(response, 'value', {})) {
            uri =
              graphConfig.graphGroupsEndpoint +
              '/' +
              response.value[0].id +
              '/members' +
              params;
          }

          callMSGraph('GET', uri, token.accessToken, callback);
        }
      );
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getUserPhoto(provider, callback, userId, size = '96x96') {
  provider
    .getAccessToken()
    .then((token) => {
      return callMSGraph(
        'GET',
        graphConfig.graphUsersEndpoint +
          '/' +
          userId +
          '/photos/' +
          size +
          '/$value', // 48x48, 64x64, 96x96, 120x120, 240x240
        token.accessToken,
        callback,
        null,
        {
          userId: userId,
        }
      );
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getAllUserPresence(provider, callback, userIds) {
  provider
    .getAccessToken()
    .then((token) => {
      return callMSGraph(
        'POST',
        'https://graph.microsoft.com/beta/communications/getPresencesByUserId',
        token.accessToken,
        callback,
        userIds
      );
    })
    .catch((error) => {
      console.log(error);
    });
}
